<template>
  <div class="cosmetic_dashboard customize_theme">
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title" style="margin-bottom: 10px">筛选</h2>
      <el-form
        ref="ruleForm"
        size="small"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item label="品类" prop="category">
              <el-select v-model="form.category" placeholder="请选择品类">
                <el-option
                  v-for="item in categoryOptions"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间范围" prop="timeRange">
              <el-select v-model="form.timeRange" placeholder="请选择时间范围">
                <el-option
                  v-for="item in timeRange"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="产品分类" prop="productCategory">
              <el-radio-group v-model="form.productCategory">
                <el-radio label="benefit">benefit</el-radio>
                <el-radio label="priceTier">price tier</el-radio>
                <el-radio label="topSalesGrowthProducts"
                  >top sales growth products
                </el-radio>
                <el-radio label="newLaunchedProducts"
                  >new launched products
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="22">
            <el-button
              type="primary"
              :disabled="axiosLoading"
              @click="getTableList('ruleForm')"
              >确定
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="border-radius: 10px">
      <p class="insight_title">输出结果</p>
      <table-legend :especial="especial" />
      <el-table
        v-loading="loading"
        class="no_hover_table"
        :data="tableData"
        :span-method="spanMethod"
        :header-cell-style="headerCellStyle"
        :cell-style="cellStyle"
        @cell-click="cellClick"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
      >
        <el-table-column
          v-for="item in columnData"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #header="{ column }">
            {{ column.label.split("||")[0] }}
            <br />
            <span style="word-break: break-word">
              {{ column.label.split("||")[1] }}
            </span>
          </template>
          <template #default v-if="item.children">
            <el-table-column
              v-for="childItem in item.children"
              :key="childItem.prop"
              :prop="childItem.prop"
              :label="childItem.label"
              :width="childItem.width"
              align="center"
              :formatter="valueFormatter"
            >
              <template #header="{ column }">
                {{ column.label.split("||")[0] }}
                <br />
                <span style="word-break: break-word">
                  {{ column.label.split("||")[1] }}
                </span>
              </template>
            </el-table-column>
          </template>
          <template #default="{ row }" v-else>
            <span style="word-break: break-word">{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="950px"
      top="10vh"
      destroy-on-close
    >
      <row-dialog
        v-if="dialogType === 'row'"
        :params="dialogParams"
        :options="{
          threshold: options.threshold,
          sampleNumber: options.sampleNumber,
          specialProductCategoryFlag,
        }"
        @allProductNumber="allProductNumber = $event"
      />
      <cell-dialog
        v-else
        :params="dialogParams"
        :form="form"
        :groupTwo="groupTwo"
        :firstClaimLabel="firstClaimLabel"
        :secondClaimLabel="secondClaimLabel"
        :options="{
          threshold: options.threshold,
          sampleNumber: options.sampleNumber,
          specialProductCategoryFlag,
        }"
      />
    </el-dialog>
  </div>
</template>

<script>
import { h } from 'vue';
import rowDialog from "./rowDialog";
import cellDialog from "./cellDialog";
import tableLegend from "./tableLegend";
import { getBasicInfo, getResult } from "@/api/cosmetic/dashBoard";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
      categoryOptions: [],
      // timeRange: ["202101", "202112"],
      timeRange: [],
      form: {
        category: "",
        timeRange: [],
        productCategory: "benefit",
      },
      rules: {
        category: [
          {
            required: true,
            message: "请选择品类",
            trigger: "change",
          },
        ],
        timeRange: [
          {
            required: true,
            message: "请选择时间范围",
            trigger: "change",
          },
        ],
        productCategory: [
          {
            message: "请选择产品分类",
            trigger: "change",
          },
        ],
      },
      columnData: [
        {
          prop: "groupOne",
          label: "",
          width: 60,
        },
        {
          prop: "groupTwo",
          label: "",
          width: 82,
        },
      ],
      tableData: [],
      dialogVisible: false,
      dialogTitle: "row click",
      dialogParams: {
        secondGroup: "",
      },
      dialogType: "cell",
      firstClaimLabel: "",
      secondClaimLabel: "",
      loading: true,
      cellColor: [],
      options: {
        // 特定产品分类不加灰色背景
        specialProductCategory: [
          "topSalesGrowthProducts",
          "newLaunchedProducts",
        ],
        // 阈值
        threshold: 1.05,
        // 样本数量
        sampleNumber: 5,
      },
      allProductNumber: 0,
      especial: false, // 是否为特定产品分类(不加灰色背景)
      groupTwo: "",
    };
  },
  computed: {
    ...mapGetters(["axiosLoading"]),
    spanArr() {
      let pos = 0,
        res = [1];
      // 判断几行的groupOne是否一致需要合并
      for (let i = 1; i < this.tableData.length; i++) {
        // 判断当前元素与上一个元素是否相同
        if (this.tableData[i].groupOne === this.tableData[i - 1].groupOne) {
          res[pos] += 1;
          res.push(0);
        } else {
          res.push(1);
          pos = i;
        }
      }
      return res;
    },

    specialProductCategoryFlag() {
      return this.options.specialProductCategory.includes(
        this.form.productCategory
      );
    },
  },
  async beforeCreate() {
    const {
      data: { category, timeRange, tableTitle },
    } = await getBasicInfo();
    this.categoryOptions = category;
    this.timeRange = timeRange;
    this.form.timeRange = timeRange[0];
    this.formatTableHeader(tableTitle);
  },
  methods: {
    str2ColItem(str) {
      // 列宽度调整
      // 四个汉子最小宽度82
      // double 类型数据最小宽度76
      const res = str.split("||");
      const prop = res[2];
      let width = null;
      switch (prop) {
        case "fragrance":
          width = 76;
          break;
        case "texture":
          width = 78;
          break;
        case "skinType":
          width = 76;
          break;
        case "reasearchCapabilty":
          width = 99;
          break;
        case "authority":
          width = 90;
          break;
        case "ingredientFormula":
          width = 96;
          break;
        case "technologyPatent":
          width = 106;
          break;
        case "mechanism":
          width = 82;
          break;
        case "functionality":
          width = 82;
          break;
        default:
          width = null;
      }
      return {
        label: res[0] + "||" + res[1],
        prop,
        width,
      };
    },
    formatTableHeader(titleObj) {
      const res = [];
      for (let [key, value] of Object.entries(titleObj)) {
        const keyObj = this.str2ColItem(key);
        keyObj.children = value.map((item) => this.str2ColItem(item));
        res.push(keyObj);
      }
      this.columnData = [...this.columnData, ...res];
      this.loading = false;
    },
    spanMethod({ row, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.groupOne === row.groupTwo) {
          return [0, 0];
        } else {
          const rowSpan = this.spanArr[rowIndex];
          return [rowSpan, 1];
        }
      } else if (columnIndex === 1) {
        if (row.groupOne === row.groupTwo) {
          return [1, 2];
        } else {
          return [1, 1];
        }
      }
    },
    headerCellStyle({ rowIndex, columnIndex, column }) {
      // 处理左上角的单元格
      // 第一行表头的背景色
      if (rowIndex === 0) {
        if (columnIndex === 0) {
          this.$nextTick(() => {
            if (document.getElementsByClassName(column.id).length !== 0) {
              document
                .getElementsByClassName(column.id)[0]
                .setAttribute("colSpan", "2");
            }
          });
          return {
            backgroundColor: "#fff",
          };
        } else if (columnIndex === 1) {
          return { display: "none" };
        } else {
          return {
            backgroundColor: "#fff",
          };
        }
      }
    },
    cellStyle({ columnIndex }) {
      // 二级分类列
      if (columnIndex === 1) {
        return {
          cursor: "pointer",
          backgroundColor: "#f5f7fa",
        };
        // 数据格
      } else {
        return {
          cursor: "pointer",
        };
      }
    },
    createLabel(v1, v2, color) {
      let fontColor = "#c0c3cb";
      let bgColor = "#fff";
      // 阈值大等于1.05，变色
      if (v1 >= this.options.threshold) fontColor = color; 
      // 样本数小于5，背景变灰色
      if (v2 < this.options.sampleNumber) bgColor = "#dfdfdf";

      return h('span', {
        class: 'declareSpan',
        style: {
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "12px",
          color: fontColor,
          backgroundColor: this.especial ? "#fff" : bgColor,
        }
      }, v1);
      // return (
      //   <span
      //     class="declareSpan"
      //     style={{
      //       flex: 1,
      //       display: "flex",
      //       alignItems: "center",
      //       justifyContent: "center",
      //       fontSize: "12px",
      //       backgroundColor: this.especial ? "#fff" : bgColor,
      //       color: fontColor,
      //     }}
      //   >
      //     {v1}
      //   </span>
      // );
    },
    valueFormatter(row, column, cellValue) {
      if (
        cellValue.split("||")[2] === "na" &&
        cellValue.split("||")[3] == "0"
      ) {
        cellValue = cellValue.replace("||0", "");
      }
      const label1 = this.createLabel(
        cellValue.split("||")[0],
        cellValue.split("||")[1],
        "#2C99B6"
      );
      const label2 = this.createLabel(
        cellValue.split("||")[2],
        cellValue.split("||")[3],
        "#F37092"
      );
      return h('div', {
        style: {
          width: '100%',
          display: 'flex',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%'
        }
      }, [
        label1,
        h('i', {
          style: {
            borderLeft: '1px solid #ebeef5'
          }
        }),
        label2
      ]);
      // return (
      //   <div style="width: 100%;display: flex;position: absolute;top: 0;left: 0;height: 100%;">
      //     {label1}
      //     <i style="border-left: 1px solid #ebeef5;"></i>
      //     {label2}
      //   </div>
      // );
    },
    secondClaim2firstClaim(property) {
      const tempArr = this.columnData.filter((item) => item.children);
      return tempArr.find((item) =>
        item.children.find((child) => child.prop === property)
      );
    },
    cellClick(row, column) {
      this.groupTwo = row.groupTwo;
      this.allProductNumber = "--";
      // row click
      if (column.property === "groupTwo") {
        this.dialogType = "row";
        this.dialogTitle = `${row.groupTwo}（总样本量${this.allProductNumber}）- 产品宣称详情`;
        this.dialogParams = Object.assign(
          {
            secondGroup: row.groupTwo,
          },
          this.form
        );
        this.dialogVisible = true;
        // cell click
      } else if (column.property !== "groupOne") {
        this.dialogType = "cell";
        const firstClaim = this.secondClaim2firstClaim(column.property);
        this.firstClaimLabel = firstClaim.label;
        this.secondClaimLabel = column.label;
        this.dialogTitle = `${row.groupTwo}: ${this.firstClaimLabel.split("||")[0]
          } - ${this.secondClaimLabel.split("||")[0]}`;
        this.dialogParams = Object.assign(
          {
            secondGroup: row.groupTwo,
            firstClaim: firstClaim.prop,
            secondClaim: column.property,
          },
          this.form
        );
        this.dialogVisible = true;
      }
    },
    changeGroupTwoRowColor(cell, color) {
      const nodeList = [
        ...cell.parentNode.getElementsByClassName("declareSpan"),
      ];
      // 有传入的颜色表示统一高亮
      // 没有传入的颜色表示还原
      for (let i = 0; i < nodeList.length; i++) {
        if (color) {
          const itemColor = nodeList[i].style.backgroundColor;
          this.cellColor[i] = itemColor;
          nodeList[i].style.backgroundColor =
            itemColor === "rgb(255, 255, 255)" ? color : itemColor;
        } else {
          nodeList[i].style.backgroundColor = this.cellColor[i];
        }
      }
    },
    cellMouseEnter(row, column, cell) {
      if (column.property === "groupTwo") {
        cell.style.backgroundColor = "rgba(83, 186, 210, 0.15)";
        this.changeGroupTwoRowColor(cell, "rgba(83, 186, 210, 0.15)");
      } else if (column.property !== "groupOne") {
        const declareSpanDoms = cell.getElementsByClassName("declareSpan");
        const textColor = declareSpanDoms.item(0).style.backgroundColor;
        const numberColor = declareSpanDoms.item(1).style.backgroundColor;
        this.cellColor = [textColor, numberColor];
        declareSpanDoms.item(0).style.backgroundColor =
          textColor === "rgb(255, 255, 255)"
            ? "rgba(83, 186, 210, 0.15)"
            : textColor;
        declareSpanDoms.item(1).style.backgroundColor =
          numberColor === "rgb(255, 255, 255)"
            ? "rgba(83, 186, 210, 0.15)"
            : numberColor;
      }
    },
    cellMouseLeave(row, column, cell) {
      if (column.property === "groupTwo") {
        cell.style.backgroundColor = "#f5f7fa";
        this.changeGroupTwoRowColor(cell);
      } else if (column.property !== "groupOne") {
        const declareSpanDoms = cell.getElementsByClassName("declareSpan");
        declareSpanDoms.item(0).style.backgroundColor = this.cellColor[0];
        declareSpanDoms.item(1).style.backgroundColor = this.cellColor[1];
      }
    },
    getTableList(formName) {
      this.especial = this.specialProductCategoryFlag;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let params = Object.assign(
            { claimIndexType: "dimension" },
            this.form
          );
          const dimensionData = await getResult(params);
          params = Object.assign({ claimIndexType: "numeric" }, this.form);
          const numericData = await getResult(params);
          // 合并dimensionData numericData
          this.tableData = dimensionData.data.map((item, i) => {
            // 遍历每行数据 合并value
            for (const [k, v] of Object.entries(item)) {
              if (k === "groupOne" || k === "groupTwo") continue;
              item[k] = v + "||" + numericData.data[i][k];
            }
            return item;
          });
          this.loading = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {
    allProductNumber(val) {
      if (this.dialogType === "row")
        this.dialogTitle = `${this.dialogParams.secondGroup}（总样本量${val}）- 产品宣称详情`;
    },
  },
  components: {
    rowDialog,
    cellDialog,
    tableLegend,
  },
};
</script>

<style scoped lang="scss">
.cosmetic_dashboard {
  .insight_title {
    font-size: 20px;
  }
}
</style>
