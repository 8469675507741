import request from "@/utils/axios";

export function getBasicInfo() {
  return request.get("/cosmetic/dashboard/basicinfo");
}

export function getResult(params) {
  return request.get("/cosmetic/dashboard/result", params);
}

export function getRowInfo(params) {
  return request.get("/cosmetic/dashboard/row/bar", params);
}

export function getRowNumber(params) {
  return request.get("/cosmetic/dashboard/row/number", params);
}

export function getCellData(params) {
  return request.get("/cosmetic/dashboard/cell", params);
}

export function getCellImg(params) {
  return request.get("/cosmetic/dashboard/cell/img", params);
}
