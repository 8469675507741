<template>
  <div>
    <div style="display: flex; height: 300px" v-loading="loading">
      <div style="flex: 1">
        <p class="title_class">一级宣称产品数分布</p>
        <normal-bar
          :seriesData="firstClaimSeriesData"
          :tooltipFormatter="tooltipFormatter"
        />
      </div>
      <div style="flex: 1">
        <p class="title_class">二级宣称产品数分布</p>
        <normal-bar
          :seriesData="secondClaimSeriesData"
          :tooltipFormatter="tooltipFormatter"
        />
      </div>
    </div>
    <div v-loading="tableLoading" style="margin-bottom: 20px">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <p class="title_class">宣称对应产品数及效果</p>
        <table-legend style="margin-bottom: 0" />
      </div>
      <div
        style="display: flex; justify-content: flex-end; align-items: center"
      >
        <el-radio-group v-model="type" @change="getTableList">
          <el-radio label="num">数量</el-radio>
          <el-radio label="ratio">占比</el-radio>
        </el-radio-group>
      </div>
      <el-table
        class="no_hover_table"
        :data="tableData"
        border
        size="small"
        :span-method="spanMethod"
        :cell-style="cellStyle"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        @cell-click="cellClick"
      >
        <el-table-column
          v-for="item in columnData"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
          :formatter="item.formatter"
        />
      </el-table>
    </div>
    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="950px"
      destroy-on-close
    >
      <cell-dialog
        :params="dialogParams"
        :firstClaimLabel="firstClaimLabel"
        :secondClaimLabel="secondClaimLabel"
        :options="options"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, h } from "vue";
import NormalBar from "@/components/echarts/normalBar";
import cellDialog from "./cellDialog";
import tableLegend from "./tableLegend";

import { getRowInfo, getRowNumber } from "@/api/cosmetic/dashBoard";

const emit = defineEmits(["allProductNumber"]);

const props = defineProps({
  params: {
    type: Object,
    default() {
      return {};
    },
  },
  options: {
    type: Object,
    default() {
      return {};
    },
  },
});

const loading = ref(true);
const tableLoading = ref(true);
const firstClaimSeriesData = ref([]);
const secondClaimSeriesData = ref([]);
const allProductNumber = ref(0);
const type = ref("num");
const valueFormatter = (cellValue, color) => {
  let res = h('span', { style: { color: '#C0C3CB' } }, 'na');
  if (cellValue !== "na") {
    const cell = cellValue.split("||")[0];
    // 阈值大等于1.05，变色
    if (cell >= props.options.threshold) {
      res = h('span', { style: { color } }, cell);
    } else {
      res = h('span', { style: { color: '#C0C3CB' } }, cell);
    }
  }
  return res;
};
const columnData = ref([
  {
    prop: "firstClaim",
    label: "一级宣称",
    width: null,
    formatter: (row, column, cellValue) => {
      return h('div', [
        h('span', {}, cellValue.split("||")[0]),
        h('br'),
        h('span', { style: 'word-break: break-word' }, cellValue.split("||")[1])
      ]);
      // return (
      //   <div>
      //     <span>{cellValue.split("||")[0]}</span>
      //     <br />
      //     <span style="word-break: break-word">{cellValue.split("||")[1]}</span>
      //   </div>
      // );
    },
  },
  {
    prop: "firstClaimProductNum",
    label: "一级宣称对应产品数",
    width: null,
  },
  {
    prop: "secondClaim",
    label: "二级宣称",
    width: 200,
    formatter: (row, column, cellValue) => {
      return h('div', [
        h('span', {}, cellValue.split("||")[0]),
        h('br'),
        h('span', {}, cellValue.split("||")[1])
      ]);
      // return (
      //   <div>
      //     <span>{cellValue.split("||")[0]}</span>
      //     <br />
      //     <span>{cellValue.split("||")[1]}</span>
      //   </div>
      // );
    },
  },
  {
    prop: "secondClaimProductNum",
    label: "二级宣称对应产品数",
    width: null,
  },
  {
    prop: "dimensionClaim",
    label: "文字宣称指数",
    width: 120,
    formatter: (row, column, cellValue) => {
      return valueFormatter(cellValue, "#2C99B6");
    },
  },
  {
    prop: "numericClaim",
    label: "数字宣称指数",
    width: 120,
    formatter: (row, column, cellValue) => {
      return valueFormatter(cellValue, "#F37092");
    },
  },
]);
const tableData = ref([]);
const tooltipFormatter = (params) => {
  const value = params[0].value;
  let str = `<p>产品数：${value}</p>`;
  str += `<p>占比：${((value / allProductNumber.value) * 100).toFixed()}%</p>`;
  return str;
};

const initData = async () => {
  loading.value = true;
  const {
    data: { firstClaim, secondClaim, allProductNum },
  } = await getRowInfo(props.params);
  firstClaimSeriesData.value = firstClaim;
  secondClaimSeriesData.value = secondClaim;
  emit("allProductNumber", allProductNum);
  allProductNumber.value = allProductNum;
  await getTableList();
  loading.value = false;
};
initData();

const getTableList = async () => {
  tableLoading.value = true;
  const { data } = await getRowNumber(
    Object.assign({ type: type.value }, props.params)
  );
  tableData.value = data;
  tableLoading.value = false;
};

const spanArr = computed(() => {
  let pos = 0,
    res = [1];
  // 判断几行的name1是否一致需要合并
  for (let i = 1; i < tableData.value.length; i++) {
    // 判断当前元素与上一个元素是否相同
    if (tableData.value[i].firstClaim === tableData.value[i - 1].firstClaim) {
      res[pos] += 1;
      res.push(0);
    } else {
      res.push(1);
      pos = i;
    }
  }
  return res;
});
const spanMethod = ({ rowIndex, columnIndex }) => {
  if (columnIndex === 0 || columnIndex === 1) {
    const rowSpan = spanArr.value[rowIndex];
    return [rowSpan, 1];
  }
};

const cellStyle = ({ row, column, columnIndex }) => {
  // 一级宣称列 二级宣称列
  if (columnIndex === 0 || columnIndex === 2) {
    return {
      backgroundColor: "#f5f7fa",
    };
    // 数据格
  } else if (columnIndex > 3) {
    // 样本数小于5，背景变灰色
    const cellValue = row[column.property];
    if (cellValue !== "na" && !props.options.specialProductCategoryFlag) {
      const specimen = cellValue.split("||")[1];
      if (specimen < props.options.sampleNumber) {
        return {
          cursor: "pointer",
          backgroundColor: "#dfdfdf",
        };
      }
    }
    return {
      cursor: "pointer",
    };
  }
};
const cellColor = ref("");
const cellMouseEnter = (row, column, cell) => {
  if (
    column.property === "dimensionClaim" ||
    column.property === "numericClaim"
  ) {
    const color = cell.style.backgroundColor;
    cellColor.value = color;
    cell.style.backgroundColor =
      color === "" ? "rgba(83, 186, 210, 0.15)" : color;
  }
};
const cellMouseLeave = (row, column, cell) => {
  if (
    column.property === "dimensionClaim" ||
    column.property === "numericClaim"
  ) {
    cell.style.backgroundColor = cellColor.value;
  }
};

const dialogVisible = ref(false);
const dialogTitle = ref("");
const dialogParams = ref({});
const firstClaimLabel = ref("");
const secondClaimLabel = ref("");
const cellClick = (row, column) => {
  if (
    column.property === "dimensionClaim" ||
    column.property === "numericClaim"
  ) {
    firstClaimLabel.value = row.firstClaim;
    secondClaimLabel.value = row.secondClaim;
    const firstClaim = row.firstClaim.split("||");
    const secondClaim = row.secondClaim.split("||");
    dialogTitle.value = `${props.params.secondGroup}: ${firstClaim[0]} - ${secondClaim[0]}`;
    dialogParams.value = Object.assign(
      {
        firstClaim: firstClaim[2],
        secondClaim: secondClaim[2],
      },
      props.params
    );
    dialogVisible.value = true;
  }
};
</script>

<style scoped lang="scss">
.title_class {
  font-size: 16px;
}
</style>
