<template>
  <div class="table_container">
    <div class="table_title">
      <slot></slot>
    </div>
    <ul class="table_content">
      <template v-if="value && JSON.stringify(value) !== '{}'">
        <li v-for="[key, val] of Object.entries(value)" :key="key">
          <div
            class="li_item"
            :style="{
              backgroundColor: judgeValue(val) ? '#CBEBF7' : '#f5f7f9',
              height: dataAmountHeight,
            }"
          >
            <span>{{ key.split("||")[0] }}</span>
            <span>{{ key.split("||")[1] }}</span>
          </div>

          <el-popover
            v-if="!val.startsWith('na')"
            placement="top"
            trigger="hover"
          >
            <template #reference>
              <div
                class="li_item li_item_hover"
                @click="showProductPicture(key)"
                @mouseleave.self="hidePopover()"
                @mouseenter.self="showPopover(key)"
                :style="{
                  color: judgeValue(val)
                    ? claimType === 'text'
                      ? '#2C99B6'
                      : '#F37092'
                    : '#c0c3cb',
                  backgroundColor: judgeSpecimen(val) ? '#dfdfdf' : '#fff',
                  height: dataAmountHeight,
                }"
              >
                {{ val !== "na" ? val.split("||")[0] : val }}
              </div>
            </template>
            <div style="text-align: center">
              <img
                v-if="!poppverImgLength && !poppverImg"
                style="width: 100px; height: 100px"
                src="../../assets/image_loading.gif"
              />
              <el-image
                v-else-if="!poppverImgLength && poppverImg"
                style="width: 100px; height: 100px"
                :src="poppverImg"
              />
              <span v-else>暂无图片数据</span>
            </div>
          </el-popover>

          <div
            v-else
            class="li_item"
            :style="{
              color: judgeValue(val)
                ? claimType === 'text'
                  ? '#2C99B6'
                  : '#F37092'
                : '#c0c3cb',
              backgroundColor: judgeSpecimen(val) ? '#dfdfdf' : '#fff',
              height: dataAmountHeight,
            }"
          >
            {{ val !== "na" ? val.split("||")[0] : val }}
          </div>
        </li>
      </template>
      <li v-else>
        <div class="li_item value_span">无数据</div>
      </li>
    </ul>

    <el-dialog
      v-model="loading"
      width="1157px"
      top="10vh"
      destroy-on-close
      custom-class="show_picture"
    >
      <cell-dialog-product-pic
        :pictureList="pictureList"
      ></cell-dialog-product-pic>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import cellDialogProductPic from "./cellDialogProductPic";
import { getCellImg } from "@/api/cosmetic/dashBoard";
import { ElMessage } from "element-plus";
// const baseURL = process.env.VUE_APP_API_HOST;

const props = defineProps({
  value: {
    type: Object || null,
    default() {
      return null;
    },
  },
  options: {
    type: Object,
    default() {
      return null;
    },
  },
  dataAmount: {
    type: Number,
    default: 0,
  },
  claimType: {
    type: String,
    default: "text",
  },
  declare: {
    type: Object || null,
    default() {
      return null;
    },
  },
  form: {
    type: Object || null,
    default() {
      return null;
    },
  },
  groupTwo: {
    type: String,
    default: "",
  },
});

const loading = ref(false);
const judgeValue = (cellValue) => {
  if (cellValue !== "na") {
    const cell = cellValue.split("||")[0];
    // 阈值大等于1.05，变蓝色
    return cell >= props.options.threshold;
  } else {
    return false;
  }
};
const judgeSpecimen = (cellValue) => {
  if (cellValue !== "na" && !props.options.specialProductCategoryFlag) {
    const specimen = cellValue.split("||")[1];
    // 样本数小于5，背景变灰色
    if (cellValue.split("||")[0] === "na") {
      return false;
    } else {
      return specimen < props.options.sampleNumber;
    }
  } else {
    return false;
  }
};
const dataAmountHeight = computed(() => {
  return (props.dataAmount ? props.dataAmount * 69 : 69) + "px";
});

const pictureList = ref(null);
const showProductPicture = async (key) => {
  const params = props.declare;
  params.claimIndexType =
    props.claimType === "number" ? "numeric" : "dimension";
  params.claimLevel3 = key.split("||")[2];
  params.category = props.form.category;
  params.timeRange = props.form.timeRange;
  params.productCategory = toLine(props.form.productCategory);
  params.secondGroup = props.groupTwo;
  const { data } = await getCellImg(params);
  if (!data.length) {
    return ElMessage({
      message: "暂无数据",
      type: "warning",
    });
  }
  pictureList.value = data.map((item) => {
    item.active = false;
    return item;
  });
  loading.value = !loading.value;
};

const poppverImg = ref("");
poppverImg.value = "";
const poppverImgLength = ref(false);
const showPopover = async (key) => {
  const params = props.declare;
  params.claimIndexType =
    props.claimType === "number" ? "numeric" : "dimension";
  params.claimLevel3 = key.split("||")[2];
  params.category = props.form.category;
  params.timeRange = props.form.timeRange;
  params.productCategory = toLine(props.form.productCategory);
  params.secondGroup = props.groupTwo;
  const { data } = await getCellImg(params);
  if (!data.length) {
    poppverImgLength.value = true;
    return;
  }
  poppverImg.value = data[0].link;
};

const hidePopover = () => {
  poppverImg.value = "";
};

//驼峰转空格
const toLine = (name) => {
  return name.replace(/([A-Z])/g, " $1").toLowerCase();
};
</script>

<style scoped lang="scss">
.table_container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .table_title {
    border: 1px solid #ddd;
    line-height: 40px;
    text-align: center;
  }

  .table_content {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #ddd;

    li {
      flex: 1;
      display: flex;

      .li_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 10px;

        span {
          line-height: 24px;
        }
      }

      .li_item_hover {
        cursor: zoom-in;
      }

      .li_item_hover:hover {
        background-color: rgb(83, 186, 210, 0.15) !important;
      }

      .value_span {
        color: #c0c3cb;
        line-height: 48px;
      }
    }
  }
}
</style>
<style lang="scss">
.el-dialog.show_picture {
  .el-dialog__header {
    // display: none;
    padding: 0;
  }
}
</style>
