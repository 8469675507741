<template>
  <div class="cell_legend">
    <i class="color_i" :style="{ backgroundColor: color }"></i>
    <div class="legend_container">
      <p style="font-size: 20px">{{ value }}</p>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#d0d0d0",
  },
  value: {
    type: Number,
    default: 100,
  },
  title: {
    type: String,
    default: "所有产品",
  },
});
</script>

<style scoped lang="scss">
.cell_legend {
  display: flex;
  align-items: center;

  .color_i {
    display: block;
    width: 32px;
    height: 14px;
    margin-right: 14px;
  }

  .legend_container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
