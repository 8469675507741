<template>
  <div class="table_container">
    <div class="table_title">
      <div class="title_div chinese">{{ title.chinese }}</div>
      <div class="title_div english">{{ title.english }}</div>
    </div>
    <div @click="toProductLibrary" class="interlinkage">
      <svg-icon class-name="interlinkage" class="icon"> </svg-icon>
      {{ activeData.productName }}
    </div>
    <div class="image_box">
      <div class="lantern_slide">
        <div class="arrow_box">
          <div @click="prevClick()" class="round">
            <svg-icon class-name="dashboard_arrow-left" class="icon">
            </svg-icon>
          </div>
          <div @click="nextClick()" class="round">
            <svg-icon class-name="dashboard_arrow-right" class="icon">
            </svg-icon>
          </div>
        </div>
        <div class="active_image">
          <!-- <ocr-image :ocrData="activeData"></ocr-image> -->
          <el-image :src="activeData.link" />
        </div>
      </div>

      <div class="image_list">
        <div
          class="item_image_div"
          @click="switchover(index, item)"
          v-for="(item, index) in renderData"
          :key="item.id"
        >
          <div :class="['item_image', { image_border: item.active }]">
            <img v-lazy="item.link" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
// import ocrImage from "./ocrImage";
import { useRouter } from "vue-router";
import { repoParams } from "@/api/productRepo";

const router = useRouter();

const activeData = ref({});
const renderData = ref([]);
const title = ref({});
const activeIndex = ref(0);
const props = defineProps({
  pictureList: {
    type: Array,
    default() {
      return [];
    },
  },
});

const prevClick = () => {
  const length = renderData.value.length - 1;
  activeIndex.value = activeIndex.value ? activeIndex.value - 1 : length;
  activeData.value = renderData.value[activeIndex.value];
  codeBlock(activeIndex.value);
};

const nextClick = () => {
  activeIndex.value =
    activeIndex.value === renderData.value.length - 1
      ? 0
      : activeIndex.value + 1;
  activeData.value = renderData.value[activeIndex.value];
  codeBlock(activeIndex.value);
};
const switchover = (index, item) => {
  activeIndex.value = index;
  activeData.value = item;
  codeBlock(index);
};

const initData = () => {
  renderData.value = props.pictureList.map((item) => item);
  renderData.value[0].active = true;
  const first = renderData.value[0];
  activeIndex.value = 0;
  activeData.value = first;

  const claimLevel1 = first.claimLevel1 ? first.claimLevel1.split("||") : "";
  const claimLevel2 = first.claimLevel2 ? first.claimLevel2.split("||") : "";
  const claimLevel3 = first.claimLevel3 ? first.claimLevel3.split("||") : "";
  if (claimLevel3) {
    title.value.english =
      claimLevel1[1] + "/" + claimLevel2[1] + "/" + claimLevel3[1];
    title.value.chinese =
      claimLevel1[0] + "/" + claimLevel2[0] + "/" + claimLevel3[0];
  } else {
    title.value.english = claimLevel1[1] + "/" + claimLevel2[1];
    title.value.chinese = claimLevel1[0] + "/" + claimLevel2[0];
  }
};
const codeBlock = (index) => {
  renderData.value.forEach((item) => {
    item.active = false;
  });
  renderData.value[index].active = true;
};

const timeRange = ref("");
const toProductLibrary = async () => {
  const { data } = await repoParams();
  timeRange.value = data.listTimeRange[0];

  const resolve = router.resolve({
    name: "ProductClaim",
    query: {
      category: "",
      timeRange: timeRange.value,
      productIds: activeData.value.productId,
      productName: activeData.value.productName,
    },
  });
  window.open(resolve.href, "_blank");
};
initData();
</script>

<style scoped lang="scss">
.table_title {
  margin: 6px 0 16px 0;
  font-size: 16px;
  color: #303133;

  .title_div {
    font-weight: 700;
  }

  .chinese {
    margin-bottom: 4px;
  }
}

.interlinkage {
  padding-left: 20px;
  margin-bottom: 12px;
  font-size: 16px;
  color: #409eff;
  cursor: pointer;
}

.image_box {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  box-sizing: border-box;

  .lantern_slide {
    position: relative;
    width: 560px;
    height: 733px;

    .arrow_box {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: 30px;
      padding: 0 17px;
      display: flex;
      justify-content: space-between;
      z-index: 9;

      .round {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #9ca6b3;
        cursor: pointer;

        .icon {
          color: #f0f2f5;
        }
      }
    }

    .active_image {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      position: relative;

      .el-image {
        height: auto;
      }
    }
  }

  .image_list {
    width: 505px;
    height: 733px;
    overflow-x: auto;
    padding: 0 23px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;

    .item_image_div:nth-child(odd) {
      margin-right: 36px;
    }

    .item_image {
      margin-bottom: 36px;
      width: 200px;
      height: 220px;
      overflow: hidden;
      border: 2px solid #f0f2f5;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image_border {
      border: 2px solid #409eff;
    }
  }
}
</style>
