<template>
  <div
    style="
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 14px;
    "
  >
    <svg-icon class-name="question" style="font-size: 18px" />
    <legend-item text="非显著指标" />
    <legend-item text="文字宣称显著指标（≥1.05）" color="#2C99B6" />
    <legend-item text="数字宣称显著指标（≥1.05）" color="#F37092" />
    <legend-item v-if="!variable" text="样本量<5" backgroundColor="#dfdfdf" />
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import LegendItem from "./legendItem";

const props = defineProps({
  especial: {
    type: Boolean,
  },
});

let variable = ref(false);

watch(
  () => props.especial,
  (val) => {
    variable.value = val;
  }
);
</script>

<style scoped></style>
