<template>
  <div className="annularPieDiv" :ref="ref"></div>
</template>

<script>
/*
 * data: number
 * */

import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([PieChart, CanvasRenderer]);
import { nextTick } from "vue";

export default {
  name: "one-data-annular",
  data() {
    return {
      myChart: "",
      timer: null,
    };
  },
  props: {
    // [100,40,20]
    data: {
      default: [100, 60, 10],
    },
    ref: {
      type: String,
      default: "themeAnnular",
    },
    color: {
      type: Array,
      default() {
        return ["#d0d0d0", "#53BAD2", "#F37092", "#FEEC65"];
      },
    },
  },
  computed: {
    series() {
      const total = this.data[0];
      const angle = 360 / total / 2;
      const res = [];
      this.data.forEach((value, i) => {
        const startAngle = Math.floor(angle * value);
        const radius = ["32%", 42 + (i + 1) * 10 + "%"];
        const valObj = {
          value,
          name: "值",
          itemStyle: { color: this.color[i], opacity: i ? 0.8 : 1 },
        };
        const addValObj = {
          value: total - value,
          name: "互补值",
          itemStyle: { opacity: 0 },
        };
        const tempObj = {
          type: "pie",
          startAngle,
          radius,
          silent: true,
          label: {
            show: false,
          },
          data: [valObj, addValObj],
        };
        res.push(tempObj);
      });
      return res;
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs[this.ref]);
      this.drawPie();
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawPie() {
      this.myChart.setOption({
        series: this.series,
        textStyle: {
          fontFamily: "Microsoft YaHei",
        },
      });
    },
    resize() {
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 300);
      });
    },
  },
  watch: {
    data() {
      this.drawPie();
    },
  },
};
</script>

<style scoped lang="scss">
.annularPieDiv {
  width: 100%;
  height: 100%;
}
</style>
