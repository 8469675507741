<template>
  <div class="normalBarDiv" ref="chart"></div>
</template>

<script>
/*
 * 传seriesData，默认为空
 * 传柱图颜色，默认为'#DFE5EF'
 * percentage格式 默认正常 传‘%’
 * 是计算高度还是自适应高度
 * */

import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([BarChart, GridComponent, TooltipComponent, CanvasRenderer]);

import { nextTick } from "vue";

export default {
  name: "normal-bar",
  data() {
    return {};
  },
  props: {
    seriesData: {
      type: Array,
      default() {
        // return arr  最多20个
        return [1];
      },
    },
    color: {
      type: String,
      default: "#49B1CC",
    },
    tooltipFormatter: {
      type: Function,
      default: null,
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.chart);
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawColBar() {
      this.myChart.setOption({
        grid: {
          show: false,
          top: 30,
          bottom: 60,
          left: "8%",
          right: "6%",
        },
        xAxis: {
          type: "category",
          data: this.seriesData.map((item, i) => i + 1),
          axisLine: {
            lineStyle: {
              color: "#ddd",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#eee",
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: this.tooltipFormatter,
        },
        series: {
          type: "bar",
          data: this.seriesData,
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
          itemStyle: {
            color: this.color,
          },
        },
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#888",
        },
      });
    },
    resize() {
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 500);
      });
    },
  },
  watch: {
    seriesData() {
      this.drawColBar();
      this.myChart.resize();
    },
  },
};
</script>

<style scoped lang="scss">
.normalBarDiv {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
