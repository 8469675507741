<template>
  <div v-loading="loading">
    <div>
      <div class="cellDialog_title">
        <svg-icon class-name="iconbingtu" class="cellDialog_title_icon" />
        <span class="cellDialog_title_text">产品数分布</span>
      </div>
      <div class="product_number_container">
        <theme-annular class="annular_content" :data="annularData" />
        <div class="legend_container">
          <cell-dialog-legend :value="allProductsNum" />
          <cell-dialog-legend
            color="#53BAD2"
            :value="firstClaimNum"
            :title="`一级宣称：${firstClaimLabel.split('||')[0]}`"
          />
          <cell-dialog-legend
            color="#F37092"
            :value="secondClaimNum"
            :title="`二级宣称：${secondClaim}`"
          />
        </div>
      </div>
    </div>
    <div style="margin-top: -18px; margin-bottom: 20px">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div class="cellDialog_title">
          <svg-icon class-name="cosmetic_text" class="cellDialog_title_icon" />
          <span class="cellDialog_title_text">文字宣称</span>
        </div>
        <table-legend style="margin-bottom: 10px" />
      </div>
      <div style="display: flex">
        <cell-dialog-table
          :value="dimension"
          :form="form"
          :groupTwo="groupTwo"
          :options="options"
          :dataAmount="textClaim ? Object.entries(textClaim).length : 0"
          :declare="declare"
          claimType="text"
        >
          <div>
            <span style="font-size: 16px; margin-left: 5px">二级宣称</span>
          </div>
        </cell-dialog-table>
        <cell-dialog-table
          :value="textClaim"
          :form="form"
          :groupTwo="groupTwo"
          :options="options"
          :declare="declare"
          claimType="text"
        >
          <div>
            <span style="font-size: 16px; margin-left: 5px">三级宣称</span>
          </div>
        </cell-dialog-table>
      </div>
    </div>
    <div style="margin-bottom: 16px">
      <div class="cellDialog_title">
        <svg-icon class-name="cosmetic_number" class="cellDialog_title_icon" />
        <span class="cellDialog_title_text">数字宣称</span>
      </div>
      <div style="display: flex">
        <cell-dialog-table
          :value="numeric"
          :form="form"
          :groupTwo="groupTwo"
          :options="options"
          :dataAmount="numberClaim ? Object.entries(numberClaim).length : 0"
          :declare="declare"
          claimType="number"
        >
          <div>
            <span style="font-size: 16px; margin-left: 5px">二级宣称</span>
          </div>
        </cell-dialog-table>
        <cell-dialog-table
          :value="numberClaim"
          :form="form"
          :groupTwo="groupTwo"
          :options="options"
          :declare="declare"
          claimType="number"
        >
          <div>
            <span style="font-size: 16px; margin-left: 5px">三级宣称</span>
          </div>
        </cell-dialog-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import themeAnnular from "@/components/echarts/themeAnnular";
import CellDialogLegend from "./cellDialogLegend";
import { defineProps, ref, computed } from "vue";
import CellDialogTable from "./cellDialogTable";
import tableLegend from "./tableLegend";
import { getCellData } from "@/api/cosmetic/dashBoard";

const props = defineProps({
  params: {
    type: Object,
    default() {
      return {};
    },
  },
  options: {
    type: Object,
    default() {
      return {};
    },
  },
  firstClaimLabel: {
    type: String,
    default: "",
  },
  secondClaimLabel: {
    type: String,
    default: "",
  },
  form: {
    type: Object,
    default: () => {},
  },
  groupTwo: {
    type: String,
    default: "",
  },
});

const loading = ref(true);
const allProductsNum = ref(0);
const firstClaimNum = ref(0);
const secondClaimNum = ref(0);
const annularData = ref([]);
const textClaim = ref({});
const numberClaim = ref({});
const tableData = ref({});
const secondClaim = computed(() => {
  return `${props.firstClaimLabel.split("||")[0]} - ${
    props.secondClaimLabel.split("||")[0]
  }`;
});
const dimension = computed(() => {
  return {
    [props.secondClaimLabel]: tableData.value.dimension,
  };
});
const numeric = computed(() => {
  return {
    [props.secondClaimLabel]: tableData.value.numeric,
  };
});
const declare = computed(() => {
  return {
    claimLevel1: props.params.firstClaim,
    claimLevel2: props.params.secondClaim,
  };
});
const initData = async () => {
  loading.value = true;
  const {
    data: {
      numProduct,
      numFirstClaimProduct,
      numSecondClaimProduct,
      tableInfo,
      claimLevel3,
      numericClaim,
    },
  } = await getCellData(props.params);
  allProductsNum.value = numProduct;
  firstClaimNum.value = numFirstClaimProduct;
  secondClaimNum.value = numSecondClaimProduct;
  annularData.value = [numProduct, numFirstClaimProduct, numSecondClaimProduct];
  tableData.value = tableInfo;
  textClaim.value = claimLevel3;
  numberClaim.value = numericClaim;
  loading.value = false;
};
initData();
</script>

<style scoped lang="scss">
.cellDialog_title {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .cellDialog_title_icon {
    color: #999;
    font-size: 22px;
    margin-right: 4px;
  }

  .cellDialog_title_text {
    color: #111;
    font-weight: bold;
  }
}

.product_number_container {
  display: flex;
  align-items: center;
  margin-top: -28px;
  margin-left: -32px;

  .annular_content {
    width: 250px;
    height: 250px;
  }

  .legend_container {
    flex: 1;
    display: flex;
    justify-content: space-around;
  }
}
</style>
